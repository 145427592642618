import { useEffect, useState } from "react";
import uuidv4 from "../tools/guidgenerator";
import { useTranslation } from 'react-i18next';
import * as Constants from "../config";
import {checkArrayDefined, numberWithCommas} from "../tools/valueshelper";
import { useLocation, useNavigate } from "react-router-dom";

export default function ApartCard(props){

    const { t } = useTranslation();
    const [imagesState,setImagesState] = useState();

    const location = useLocation();
    const [apartment, setApartment] = useState(props.data);

    const navigate = useNavigate();

    var apart = {            
        id:uuidv4(),
        name:"",
        price:"",
        landarea:"",
        livingarea:"",
        propertytype:"",
        typeofoffer:"",
        neighborhood:"",
        bedrooms:"",
        showers:"",
        properties:
        [
            {id:0, name:"",options:[]}
        ]
    };

    function getImages() {
        fetch(Constants.apiurl + Constants.imagesListPath + "?id=" + apartment.id
            
        )
        .then((response) => response.json())
        .then((data) => {
            setImagesState(data);
        })
        .catch((err) => {
           console.log(err.message);
        });
    }

    function openDetails(){
        navigate({
            pathname: "/apart",
            search: "id="+apartment.id
        });
        if(location.pathname=="/apart")navigate(0);
    }

    useEffect(() => {
        getImages();
     }, []);


    return(
        <div id="apart-card" onClick={openDetails}>
            <div id="apart-image">
                <img src={checkArrayDefined(apartment.images) ? apartment.images[0].link : ""}/>
            </div>
            <div id="price">
                {numberWithCommas( apartment.price)} FCFA
            </div>
            <div id="name">                
                {apartment.name}
            </div>
            <div className="line">
            </div>
            <div id="icons">
                <div>
                    <img src="bed.svg"/>                                    
                    {apartment.bedrooms} {t('Chambres')}
                </div>
                <div>
                    <img src="bath.svg"/>                                    
                    {apartment.showers} {t('Douches')}
                </div>
                <div>
                    <img src="area.svg"/>                                    
                    {apartment.landarea} {t('m')}
                </div>
            </div>
        </div>
    )
}