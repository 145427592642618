export default function Top3(){

    const values=[
        {title:"ACHETER",
        text:
        "Acheter des biens immobiliers chez LM Platinum Estates c’est choisir des biens titrés et vérifiés auprès des autorités compétentes. Achetez vos terrains, maisons, immeubles et autres biens immobiliers en un seul clic. Nos offres sont disponibles à Yaoundé, Douala et Kribi principalement mais aussi dans d’autres villes du Cameroun."
        +"Consultez notre catalogue de terrains et maisons à vendre."
        },
        {
            title:"LOUER",
text:"Nous mettons à votre disposition des biens de qualité à des prix compétitifs. Nos conditions de travail sont claires, des contrats de location transparents pour éviter des abus en tout genre."
+"Vous recherchez une maison à louer, un appartement à louer, une boutique ou autres biens à louer. "
+"Consultez notre catalogue de biens immobiliers à louer."
    },
        {title:"CONSTRUCTION ",
        text:"Notre équipe d’expert en BTP se tient à vos dispositions pour la réalisation de vos projets de construction clés en main.\n\r "
        +"Notre objectif des biens solides et agréable à vivre.\n\r"
        +" Nos compétences :\n\r"
        +    "  - Programmation et étude de faisabilité\n\r"
        +    "  - Élaboration des plans et conception schématique\n\r"
        +    "  - Documents contractuels\n\r"
        +    "  - Construction"
}];

    return(
        <>
            <img id="values-image" src="jaja.jpg"/>
            <div className="padding-block-content">
                <div id="title">
                    Nos Services<br/>
                </div>
                <div id="values-section">
                    {values.map(item=>
                        <div id="value-card">
                            <div className="elipse"></div>
                            <div id="value-text">
                                {item.title}
                                <br/>
                                <div>
                                    {item.text}
                                </div>
                            </div>
                        </div>
                        )}
                </div>
            </div>
        </>
    )
}
