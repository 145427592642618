export default function Select(props){
    return(
        <div className="input-block">
            <div>{props.label}:</div>
            <select className="input-small" name={props.name} value={props.value} onChange={props.inputChanged}>
                <option value="">Tout</option>
                {props.values.map((x,index)=>
                    <option value={x}>{x}</option>
                )}
            </select>
        </div>
    )
}