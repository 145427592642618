import "../styles/admin.scss";
import Apartment from "../components/admin/apartment";
import React, { useEffect, useState } from "react";
import useWindowDimensions from "../tools/window_resolution";
import "../styles/admin.scss";
import Login from "./login";
import * as Constants from "../config";

export default function Admin(){
    const screenheight = useWindowDimensions().height;
    const screenwidth = useWindowDimensions().width;
    const [auth,setAuth] = useState(true);
    const [uploaderActiveState,setUploaderActiveState] = useState(false);
    
    useEffect(() => {
        checkLogin();
        }, []);

    const checkLogin = () => {
        const token = localStorage.getItem("token");
        fetch(Constants.apiurl + Constants.checkAuthPath, { headers: {"Authorization" : token} })
        .then((response) => {
            if( response.status != 401)
                setAuth(true);
            else
                setAuth(false);
        })
        .catch((err) => {
           console.log(err.message);
        });
    }

    const loginCompleted =()=>{
        setAuth(true);
    };


    return(
        <>
        <div style={{display:!auth ?"block":"none"}}> 
            <Login loginCompleted={loginCompleted}/></div>            
            <div className="admin-page" style={{display:auth ?"":"none"}}>
                <div>
                    <Apartment/>
                </div>
            </div>
        </>
    )
}