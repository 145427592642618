import { Box, Slider } from "@mui/material";
import { useEffect, useState } from "react";
import { numberWithCommas } from "../tools/valueshelper";



export default function InputFromTo(props){
    function valuetext(value) {
        return numberWithCommas(value) + props.shortlabel;
      }
    const [value, setValue] = useState([props.valuefrom, props.valueto]);

    const handleChange = (event, newValue) => {
      //setValue(newValue);
      var e = {};
      e.target = {};
      if(newValue[0]==value[0]){
        e.target.name = props.nameto;
        e.target.value = newValue[1];
        props.inputChanged(e);
      }
      if(newValue[1]==value[1]){
        e.target.name = props.namefrom;
        e.target.value = newValue[0];
        props.inputChanged(e);
      }
      setValue(newValue);
    };

    return(
        <div className="input-block from-to-input">
            <div>{props.label}:</div>
            <input pattern="\d*" maxlength="6" 
                className="input-small input-from-to" 
                id={props.id} 
                placeholder="Min" 
                onKeyDown={props.onKeyDown} 
                name={props.namefrom} 
                value={numberWithCommas(props.valuefrom)} 
                onChange={props.inputChanged}
                readOnly="true"/>
            <input pattern="\d*" 
                maxlength="6" 
                className="input-small input-from-to" 
                id={props.id} 
                placeholder="Max" 
                onKeyDown={props.onKeyDown} 
                name={props.nameto} 
                value={numberWithCommas(props.valueto)} 
                onChange={props.inputChanged}
                readOnly="true"/>
            <Slider
                getAriaLabel={() => 'Temperature range'}
                value={value}
                min={props.min}
                step={props.step}
                max={props.max}
                onChange={handleChange}
                valueLabelDisplay="auto"
                getAriaValueText={valuetext}
                valueLabelFormat={valuetext}
            />
        </div>
    )
}