import { Link } from "react-router-dom";

const linkitems =() => {
    return(
        <>            
            <div className="linkitem"> 
                Email : admin@lmplatinumestates.cm
            </div>
            <div className="linkitem">
                Appel/WhatsApp : +237 692 85 05 84
            </div>
        </>
    )
}

const archeter = () => {
    return (<>
        <div id="description">
            Acheter des biens immobiliers qui ont des documents à jour et vérifiés au près des autorités est une étape importante de votre projet. Nous vous garantissons des transactions transparentes, sécurisées et sûres devant le notaire.
        </div>
        {linkitems()}
    </>
    )
};
const louer = () => {
    return (<>
        <div id="description">
        Trouvez dans notre catalogue des bureaux, boutiques, appartements et maisons à louer dans les villes de Yaoundé, Douala et Kribi principalement.
        </div>
        {linkitems()}
    </>
    )

};
const CONSTRUIRE = () => {
    return (<>
        <div id="description">
        Nous réalisons votre maison de rêve, votre projet immobilier à moindre coûts et avec des matériaux haut de gamme. Bâtir sûre et solide est notre priorité ». Finis les bâtiments qui s’écroulent.
       </div>
        {linkitems()}
    </>
    )
};
const GESTIONLOCATIVE
= () => {
    return (<>
        <div id="description">
        Vous êtes déjà propriétaire de biens immobiliers et les gérer est un véritable casse-tête pour vous ? Profitez de votre temps libre, nous nous occupons de tout ! 
        <br/>
        Recherche des locataires, rédaction des contrats de location, contrôle des loyers etc..
       </div>
        {linkitems()}
    </>
    )
};
const VENDRESONBIENIMMOBILIER
= () => { 
    return (<>
        <div id="description">
        Vous souhaitez vendre votre bien rapidement et au meilleur prix ? 
         <br/>
         Nous vous offrons notre expertise gratuitement pour faire estimer votre bien.
        <br/>
        Nous vous prodiguons également des conseils pour faire ressortir le potentiel de votre bien afin que le futur acquéreur puisse se projeter facilement.
        <br/>
        Nous vous aidons à rendre votre bien attractif pour attirer les locataires
        <br/>
        Prenez contact avec nos commerciaux sans plus attendre.          
         </div>
        {linkitems()}
    </>
    )
};
const TOPOGRAPHIE
= () => {

    return (<>
        <div id="description">
        Nos géomètres experts sont compétents pour réaliser vos travaux dans des délais courts avec un taux de satisfaction de 100%.
      <br/>
      Contactez nos équipes pour votre projet
      </div>
        {linkitems()}
    </>
    )
};


export const menuitems=[
    {
        name:"ACCUEIL",
        anchor:"fmshop",
        descr:"",
        link:"/",
        submenu: []
    },{
        name:"ACHETER",
        anchor:"fmshop",
        descr: archeter(),
        submenu: [
            {
            name:"TERRAINS",
            link:"Terrain"
        },
        {
            name:"MAISONS",
            link:"Maison"
        },
        {
            name:"APPARTEMENTS",
            link:"Appartement meublé"
        },
        {
            name:"AUTRES",
            link:""
        },
        ]
    },{
        name:"LOUER",
        anchor:"fmshop",
        descr: louer(),
        submenu: [
            {
            name:"TERRAINS",
            link:"Terrain"
        },
        {
            name:"MAISONS",
            link:"Maison"
        },
        {
            name:"APPARTEMENTS",
            link:"Appartement meublé"
        },
        {
            name:"LOCATION MEUBLÉ",
            link:"Local Commercial"
        },
        {
            name:"BUREAU",
            link:"Bureau"
        },
        {
            name:"BOUTIQUE",
            link:""
        },
        {
            name:"AUTRE",
            link:""
        },
        ]
    },{
        name:"CONSTRUIRE",
        anchor:"fmshop",
        descr: CONSTRUIRE(),
        submenu: [
        ]
    },{
        name:"GESTION LOCATIVE",
        anchor:"fmshop",
        descr: GESTIONLOCATIVE(),
        submenu: [
        ]
    },{
        name:"VENDRE SON BIEN IMMOBILIER",
        anchor:"fmshop",
        descr: VENDRESONBIENIMMOBILIER(),
        submenu: [
        ]
    },{
        name:"TOPOGRAPHIE",
        anchor:"fmshop",
        descr: TOPOGRAPHIE(),
        submenu: [
            {
            name:"BORNAGE",
            link:""
        },
        {
            name:"TERRASSEMENT",
            link:""
        },
        {
            name:"CONCEPTION ET PLAN TOPOGRAPHIQUE",
            link:""
        },
        {
            name:"URBANISME",
            link:""
        },
        {
            name:"TIRAGE DES PLANS TOPOGRAPHIQUE",
            link:""
        },
        {
            name:"LOCATION DE MATERIEL TOPOGRAPHIQUE",
            link:""
        },
        {
            name:"IMMATRICULATION",
            link:""
        },
        {
            name:"LOTISSEMENT",
            link:""
        },
        {
            name:"MORCELLEMENT",
            link:""
        },
        {
            name:"GESTION DES LITIGES",
            link:""
        },
        ]
    },
    ];
