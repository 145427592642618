import { useEffect, useState } from "react";
import AddApartment from "./add-apartment";
import uuidv4 from "../../tools/guidgenerator";
import * as Constants from "../../config";
import * as Vaules from "../../static data/selectvalues";
import { checkArrayDefined } from "../../tools/valueshelper";

export default function Apartment(){

    const[apartmentModalOpened,setApartmentModalOpened] = useState(false);

    const[selectedApart, setSelectedApart] = useState({});

    var apart = {            
        id:uuidv4(),
        name:"",
        price:"",
        landarea:"",
        livingarea:"",
        propertytype:Vaules.propertyValues[0],
        typeofoffer:Vaules.offerValues[0],
        neighborhood:"",
        bedrooms:Vaules.chambersValues[0],
        showers:Vaules.bathValues[0],
        properties:
        [
            {id:0, name:"",options:[]}
        ],
        images:[]
    };

    const aparts = [
        {            
        id:uuidv4(),
        name:"",
        price:"",
        landarea:"",
        livingarea:"",
        propertytype:"",
        typeofoffer:"",
        neighborhood:"",
        displayOnMain:"",
        bedrooms:"",
        showers:"",
        order:99,
        properties:
        [
            {id:0, name:"",options:[]}
        ]
    },
];
    const [apartments, setApartments] = useState();

    function edit(a){
        setSelectedApart(a);
        setApartmentModalOpened(true);
    }

    function add(){        
        apart.id=uuidv4();
        setSelectedApart(apart);
        setApartmentModalOpened(true);
    }

    const saveData = (data) => {
        var d = {};
        Object.assign(d, data);
        console.log(d.price);
        d.price = d.price.replaceAll(",","").replaceAll(".","");
        const body = Constants.requestBody(d);
        fetch(Constants.apiurl + Constants.apartments, body)
        .then(response => {
            if(response.status == 401) window.location.reload(false);
            return response.json();
        })
        .then(data => {
            getData();
        })
        .catch(err => {
            console.log(err.error);
        });
    }

    function editDone(a){
        var newObj=[];
        Object.assign(newObj, apartments);
        const i = newObj.findIndex(x=> x.id == a.id);
        if(i<0){
            newObj.push(a);
        }
        else
            newObj[i]=a;
        setApartments(newObj);
        saveData(a);
        closeModal();
    }

    function getData() {
        fetch(Constants.apiurl + Constants.apartments)
        .then((response) => response.json())
        .then((data) => {
            console.log(data);
            setApartments(data);
        })
        .catch((err) => {
           console.log(err.message);
        });
    }
    useEffect(() => {
        getData();
     }, []);

    function closeModal(){
        setApartmentModalOpened(false);
    }

    const delet = (x) => {
        fetch(Constants.apiurl + Constants.deleteapartments + "?id=" + x)
        .then((data) => {
            getData();
        })
        .catch((err) => {            
            console.log(err.message);
        });
    };

    function checkboxClick(id){
        var newObj=[];
        Object.assign(newObj, apartments);
        const i = newObj.findIndex(x=> x.id == id);
        newObj[i].displayOnMain = !(newObj[i].displayOnMain);
        setApartments(newObj);
        saveData(newObj[i]);
    }

    return(
        <div>
        {apartmentModalOpened ?
        <div className="modal-window">
            <div>
                <div className="close-icon" onClick={closeModal}>X</div>
                 <AddApartment closeWindow={closeModal} saveApart={editDone} apart={selectedApart}/>
            </div>
        </div>
        : ""}

        <table className="apartment-table">
            <thead>
                <tr>
                    <th>
                        Display On Main
                    </th>
                    <th>
                        Nom
                    </th>
                    <th>
                        Prix
                    </th>
                    <th>
                        Location
                    </th>
                    <th>
                        Superficie terrain
                    </th>
                    <th>
                        Superficie habitable
                    </th>
                    <th style={{width:"220px"}}>
                        Actions
                    </th>
                </tr>
            </thead>
            <tbody>
                {!!(apartments) ? apartments.map(x=>
                    <tr>
                        <td>
                            <input type="checkbox" checked={x.displayOnMain} onChange={()=>checkboxClick(x.id)}/>
                        </td>
                        <td>{x.name}</td>
                        <td>{x.price}</td>
                        <td>{x.neighborhood}</td>
                        <td>{x.landarea}</td>
                        <td>{x.livingarea}</td>
                        <td>
                            <div onClick={()=>edit(x)} className="edit-button">Edit</div>
                            <div onClick={()=>delet(x.id)} className="delete-button">Delete</div>
                        </td>
                    </tr>
                ) : ""}
            </tbody>
        </table>
        <div onClick={add} className="add-button">Add</div>       
        </div>
    )
}
