import { useEffect, useState } from "react";
import Input from "./input";
import { useTranslation } from "react-i18next";
import * as Values from "../static data/selectvalues";
import * as Query from "../static data/queryparams";
import * as Constants from "../config";
import Select from "./select";
import { createSearchParams, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import InputFromTo from "./inputfromto";
import {checkArrayDefined } from "../tools/valueshelper";

export default function LeftMenu(){
    const maxPriceInitial = 1000000000;
    const rentMaxPriceInitial = 10000000;
    const { t } = useTranslation();
    const [maxPrice,setMaxPrice] = useState(maxPriceInitial);
    const [neighborhoodList, setNeighborhoodList] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();    
    const [filters,setFilters] = useState(getUrlParams());
    
    function getUrlParams(){
        var filt = {};
        let search = window.location.search;
        let params = new URLSearchParams(search);
        if(checkArrayDefined(params.get(Query.bedrooms)))
            filt[Query.bedrooms] = params.get(Query.bedrooms);
        if(checkArrayDefined(params.get(Query.neighborhood)))
            filt[Query.neighborhood] = params.get(Query.neighborhood);
        if(checkArrayDefined(params.get(Query.offertype)))
            filt[Query.offertype] = params.get(Query.offertype);
        if(checkArrayDefined(params.get(Query.property)))
            filt[Query.property] = params.get(Query.property);
        if(checkArrayDefined(params.get(Query.showers)))
            filt[Query.showers] = params.get(Query.showers);   
        if(checkArrayDefined(params.get(Query.pricefrom)))   
            filt[Query.pricefrom] = params.get(Query.pricefrom);
        else
            filt[Query.pricefrom] = 0;        
        if(checkArrayDefined(params.get(Query.priceto)))   
            filt[Query.priceto] = params.get(Query.priceto);
        else
            filt[Query.priceto] = maxPriceInitial;  
        if(checkArrayDefined(params.get(Query.landingareafrom)))   
            filt[Query.landingareafrom] = params.get(Query.landingareafrom);
        else
            filt[Query.landingareafrom] = 0;  
        if(checkArrayDefined(params.get(Query.landingareato)))    
            filt[Query.landingareato] = params.get(Query.landingareato);
        else
            filt[Query.landingareato] = 10000;   
        return filt;
    }

    function getNeighborhood() {
        fetch(Constants.apiurl + Constants.neighborhood, Constants.requestHeaderGet())
        .then((response) => response.json())
        .then((data) => {
            setNeighborhoodList(data);
        })
        .catch((err) => {
           console.log(err.message);
        });
    }

    useEffect(() => {
        getNeighborhood();
     }, []);

    function setMaxPriceFunction(name, value){
        var newObj = {};
        Object.assign(newObj,filters);      
        console.log(123);    
        if(name == Query.offertype &&
            value == Values.offerValues[0])
        {
            setMaxPrice(rentMaxPriceInitial);
            newObj[Query.priceto] = rentMaxPriceInitial;
        }
        else{
            setMaxPrice(maxPriceInitial);            
            newObj[Query.priceto] = maxPriceInitial;
        }         
        setFilters(newObj);
    }

    function inputChanged(e){
        const { name, value } = e.target;
        var newObj = {};
        if(`${value}`.includes(",")) value.replaceAll(",","");
        Object.assign(newObj,filters);
       // setMaxPriceFunction(name, value);
        newObj[name] = value;  
        setFilters(newObj);
    }

    function search(){
        var newFilters = {};
        Object.assign(newFilters, filters);
        newFilters = removeEmpty(newFilters);
        navigate({
            pathname: "/apartments-search",
            search: createSearchParams(newFilters).toString()
        });
        console.log(location.pathname);
        if(location.pathname=="/apartments-search")navigate(0);
    }

    function removeEmpty(obj) {
        return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null).filter(([_, v]) => v != ""));
      }

    return(
        <>
            <div id="left_menu">
                {/* <div>
                    <button className="white-button">Buy</button>
                    <button className="white-button">Rent</button>
                </div> */}
                <Select label={"Type d'offre"} name={Query.offertype} inputChanged={inputChanged} value={filters[Query.offertype]} values={Values.offerValues}/>
                <Select label={"Type de propriété"} name={Query.property} inputChanged={inputChanged} value={filters[Query.property]} values={Values.propertyValues}/>
                <Select label={"Chambres"} name={Query.bedrooms} inputChanged={inputChanged} value={filters[Query.bedrooms]} values={Values.chambersValues}/>
                <Select label={"Douches"} name={Query.showers} inputChanged={inputChanged} value={filters[Query.showers]} values={Values.bathValues}/>
                <Select label={"Ville"} name={Query.neighborhood} inputChanged={inputChanged} value={filters[Query.neighborhood]} values={neighborhoodList}/>
               
                <InputFromTo label={"Superficie (m²)"}  
                    namefrom={Query.landingareafrom} 
                    nameto={Query.landingareato} 
                    inputChanged={inputChanged} 
                    valuefrom={filters[Query.landingareafrom]} 
                    valueto={filters[Query.landingareato]} 
                    min={0}
                    max={10000}
                    step={10}
                    shortlabel=" m²"/>
                <InputFromTo
                    label={"Prix (FCFA)"}  
                    namefrom={Query.pricefrom} 
                    nameto={Query.priceto} 
                    inputChanged={inputChanged} 
                    valuefrom={filters[Query.pricefrom]} 
                    valueto={filters[Query.priceto]} 
                    min={0}
                    max={maxPrice}
                    step={1000}
                    shortlabel=" FCFA"/>
                <div onClick={search} className="blue-button">Rechercher un bien</div>   
            </div>
        </>
    )
}