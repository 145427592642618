import { useEffect, useState } from "react";
import Input from "./input";

export default function AddOptions(props){

    const [properties, setProperties] = useState(props.properties);

    const onKeyDown = (e) =>{        
        const { id, name, value } = e.target;
        if (e.key != 'Enter' || value === "") {
            return;
        }
        var newObj=[];
        Object.assign(newObj, properties);
        const i = newObj.findIndex(x=> x.id == id);
        newObj[i].options.push(value);
        newObj[i].currentoption = "";
        setProperties(newObj);
        props.updateOptions(newObj);
    }

    function valueInputChanged(e){
        const {id, name, value } = e.target;
        var newObj=[];
        Object.assign(newObj, properties);
        const i = newObj.findIndex(x=> x.id == id);
        newObj[i].currentoption=value;
        setProperties(newObj);
    }

    function optionNameInputChanged(e){
        const {id, name, value } = e.target;
        var newObj=[];
        Object.assign(newObj, properties);
        const i = newObj.findIndex(x=> x.id == id);
        newObj[i].name=value;
        setProperties(newObj);
        props.updateOptions(newObj);
    }

    function deleteOption(id,value){
        var newObj=[];
        Object.assign(newObj, properties);
        const i = newObj.findIndex(x=> x.id == id);
        const j = newObj[i].options.findIndex(x=> x == value);
        newObj[i].options.splice(j,1);
        setProperties(newObj);      
        props.updateOptions(newObj);  
    }

    function deleteProperty(id){
        var newObj=[];
        Object.assign(newObj, properties);
        const i = newObj.findIndex(x=> x.id == id);
        newObj.splice(i,1);
        setProperties(newObj);    
        props.updateOptions(newObj);    
    }

    function addOption(){
        const i = properties.length;
        var newObj = [];
        Object.assign(newObj,properties);
        newObj.push({"id":i, "name":"", "options":[]});
        setProperties(newObj);
        props.updateOptions(newObj);
    }

    return(
        <>        
            <div className="properties-block">
            {properties.map(x=>
                <div id="option">
                    <Input id={x.id} value={x.name} name="option-name" inputChanged={optionNameInputChanged} values={props.values}  label="Option Name" />  
                    <Input id={x.id} value={x.currentoption} name="option" onKeyDown={onKeyDown} inputChanged={valueInputChanged} values={props.values}  label="Option" />
                    <div className="options-blocks">
                        {x.options.map(y=>
                            <div key={y + Math.random()}>
                                {y}
                                <label onClick={()=>deleteOption(x.id,y)}>X</label>
                            </div>
                        )} 
                    </div> 
                    <div onClick={()=>deleteProperty(x.id)} className="delete-button">Delete</div>
                </div>
            )}                
                <div onClick={addOption} className="add-button" >Add Option</div>
            </div>             
        </>
    )
}