export const pricefrom = "pricefrom";
export const priceto = "priceto";
export const landingarea = "landingarea";
// export const livingareafrom = "livingareafrom";
// export const livingareato = "livingareato";
export const landingareafrom = "landingareafrom";
export const landingareato = "landingareato";
export const property = "property";
export const offertype = "offertype";
export const neighborhood = "neighborhood";
export const bedrooms = "bedrooms";
export const showers = "showers";
export const id="id";
