export const propertyValues = [
    "Appartement meublé"
    ,"Bureau"
    ,"Chambre"
    ,"Chambre meublé"
    ,"Duplex"
    ,"Fond de Commerce"
    ,"Hôtel"
    ,"Immeuble R+"
    ,"Local Commercial"
    ,"Maison"
    ,"Maison d'hôte"
    ,"Meublé"
    ,"Studio"
    ,"Studio meublé"
    ,"Terrain"
    ,"Villa"
    ,"Villa meublé"
];
export const offerValues = [
    "A louer"
    ,"A vendre"
];
export const chambersValues = [1,2,3,4,5,6,7,8,9,"10+"];
export const bathValues = [1,2,3,4,5,6,7,8,9,"10+"];