export default function Select(props){
    return(
        <div className="input-block">
            <label>{props.label}:</label>
            <select name={props.name} value={props.value} onChange={props.inputChanged}>
                {props.values.map((x,index)=>
                    <option value={x}>{x}</option>
                )}
            </select>
        </div>
    )
}