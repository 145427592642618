import { useTranslation } from "react-i18next";
import SocialLinks from "./social-links";

export default function NothingFound(){
    
    const { t } = useTranslation();

    return(
        <div id = 'no-results-found'>
            <div id = "not-found-message">{t('Aucun résultat trouvé pour les filtres fournis.')}</div>
            <div id = "contact-us-message">{t('Contactez-nous si vous avez une offre spéciale!')}</div>
            <SocialLinks/>
            <div id="contact-card">
                <div className="blue-card">
                Email : <a href="mailto:admin@lmplatinumestates.cm">admin@lmplatinumestates.cm</a><br/>
                                Calls/WhatsApp : <a href="tel:+237 692 85 05 84">+237 692 85 05 84</a><br/>
                                Calls : <a href="tel:+237 651 12 50 90">+237 651 12 50 90</a> / <a href="tel:+237 658 91 74 77">+237 658 91 74 77</a><br/>
                </div>
            </div>
        </div>
    )
}
