export function checkArrayDefined(arr) {
    if(typeof(arr) == "undefined" || arr == null || arr.length == 0 || arr == "null")
        return false;
    return true; 
}
export function checkArrayJustDefined(arr) {
    if(typeof(arr) == "undefined" || arr == null || arr == "null")
        return false;
    return true; 
}

export function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}