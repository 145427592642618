export default function SocialLinks(){
    
    const links = [
        // {
        //     name:"",
        //     link:"tel:+237 692 85 05 84",
        //     image:"phone.svg",
        //     size:10
        // },
        // {
        //     name:"",
        //     link:"mailto:admin@lmplatinumestates.cm",
        //     image:"mail.svg",
        //     size:12
        // },
        {
            name:"",
            link:"http://wa.me/237692850584",
            image:"whatsapp.svg",
            size:10
        },
        {
            name:"",
            link:"https://www.instagram.com/lm.platinumestates237",
            image:"instagram.svg",
            size:10
        },
        {
            name:"",
            link:"https://www.facebook.com/lmplatinumestates/",
            image:"facebook.svg",
            size:10
        },
        {
            name:"",
            link:"https://x.com/LmpEstates",
            image:"twitter.svg",
            size:10
        },
        {
            name:"",
            link:"https://www.tiktok.com/@lmplatinumestates?lang=fr",
            image:"tiktok.svg",
            size:10
        },
        {
            name:"",
            link:"https://www.pinterest.fr/lmpestates03/",
            image:"pinterest.svg",
            size:10
        },
    ];

    
    return(
        <div className="social-links">
            {links.map(l=>
                <a href={l.link} target="_blank">
                <div>
                    <img className={"icon-size-" + l.size} src={l.image}/>
                </div>
                </a>
            )}            
        </div>
    )
}
