export default function FindMyShop(props){

    return(
        <>
            <img id="find-now-image" src="fms.jpg"/>
            <div className="vertical-center-element">
                <div className="white-title-text">
                Trouvez le professionnel de l'immobilier local.
                    </div>
                <div className="white-text">
                Nos experts locaux sont actifs  à travers l'étendue du territoir national - également près de chez vous.
                    </div>
                    <br/>
                    <br/>
                    <div className="blue-button" onClick={props.showContactsClick}>
                        Trouver nos locaux
                    </div>
            </div>
        </>
    )
}
